<template>
    <div id="app" class="container-fluid h-100">
        <div class="row h-100 position-absolute">
            <div class="col p-0 h-100">
                <div ref="bgSvgEl" class="h-100 w-100 position-fixed"></div>
            </div>
        </div>
        <router-view />
    </div>
</template>

<script>
let is = require('is_js')
let meta_description =
    'Galeri Seni Brunei by Ministry of Culture, Youth and Sports, Brunei Darussalam. Walk around in our virtual 3D art gallery and discover new artworks from our local Bruneian artists'
let meta_url = 'https://galerisenibrunei.org/'
let meta_title = 'Galeri Seni Brunei'
let meta_image = 'https://galerisenibrunei.org/meta-image.png'
let meta_image_alt =
    "Handheld devices showing the Galeri Seni Brunei application's preview"

export default {
    name: 'App',
    metaInfo: {
        // if no subcomponents specify a metaInfo.title, this title will be used
        title: null,
        // all titles will be injected into this template
        titleTemplate: '%s | Galeri Seni Brunei',
        meta: [
            { name: 'robots', content: 'all' },
            { name: 'googlebot', content: 'all' },
            { name: 'googlebot-news', content: 'all' },
            { name: 'description', content: meta_description },
            { property: 'og' },
            { property: 'og:type', content: 'website' },
            { property: 'og:url', content: meta_url },
            { property: 'og:title', content: meta_title },
            { property: 'og:description', content: meta_description },
            { property: 'og:image', content: meta_image },
            { property: 'og:image:alt', content: meta_image_alt },
            { property: 'og:image:width', content: 598 },
            { property: 'og:image:height', content: 438 },
            { property: 'twitter:card', content: 'summary_large_image' },
            { property: 'twitter:url', content: meta_url },
            { property: 'twitter:title', content: meta_title },
            { property: 'twitter:description', content: meta_description },
            { property: 'twitter:image', content: meta_image },
        ],
    },
    mounted() {
        this.$nextTick(() => {
            let bodyTag = document.body
            let htmlTag = bodyTag.parentElement

            bodyTag.classList.add('h-100')
            htmlTag.classList.add('h-100')

            if (is.safari() || this.$route.name == 'PrivacyPolicy') {
                this.$refs.bgSvgEl.classList.remove('background__svg')
            }
        })
    },
}
</script>

<style lang="scss"></style>
