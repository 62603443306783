<template>
    <div class="h-100">
        <div class="row h-100">
            <div
                class="col-12 mt-2 pt-xl-1 d-flex justify-content-between align-items-start"
            >
                <div class="d-flex w-100 justify-content-between">
                    <div>
                        <a
                            class="logo__link"
                            href="http://www.kkbs.gov.bn/Theme/Home.aspx"
                            target="_blank"
                        >
                            <div class="logo logo_kkbs"></div>
                        </a>
                    </div>
                    <div>
                        <a
                            class="badge badge_pink social-media__link d-flex justify-content-center align-items-center"
                            href="https://www.instagram.com/galeriseni.bn/"
                            target="_blank"
                        >
                            <div class="mr-2">
                                @galeriseni.bn
                            </div>
                            <div>
                                <i class="fab fa-lg fa-instagram"></i>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
            <div class="col-12">
                <div class="row">
                    <div class="col-12 offset-xl-1 col-xl-5 text-center">
                        <div>
                            <h1
                                class="page__h1 badge badge_brown font-weight-bolder my-5 p-3"
                            >
                                GALERI SENI BRUNEI
                            </h1>

                            <p class="page__p mb-5 pt-2 px-4 text-center">
                                Galeri Seni Brunei by Ministry of Culture, Youth
                                and Sports, Brunei Darussalam. Walk around in
                                our virtual 3D art gallery and discover new
                                artworks from our local Bruneian artists.
                            </p>

                            <div class="d-none d-xl-block">
                                <div
                                    class="d-flex flex-row justify-content-center align-items-center mb-5"
                                >
                                    <a
                                        href="https://play.google.com/store/apps/details?id=io.huetech.galerisenibrunei"
                                        target="_blank"
                                        ><img
                                            class="store-logo img"
                                            alt="Get it on Google Play"
                                            src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
                                    /></a>

                                    <a
                                        href="https://apps.apple.com/us/app/galeri-seni-brunei/id1543661561"
                                        target="_blank"
                                        ><img
                                            class="store-logo store-logo__apple img"
                                            alt="Download on the App Store"
                                            src="../assets/img/appstore.svg"
                                    /></a>
                                </div>
                                <a
                                    ref="vue-start-in-browser"
                                    class="btn btn-start px-4"
                                    role="button"
                                    href="/static/webapp.html"
                                    >Start In Browser</a
                                >
                            </div>
                        </div>
                    </div>

                    <div
                        class="col-12 col-xl-5 my-auto text-center position-relative"
                    >
                        <img
                            class="showcase__img img"
                            src="../assets/img/SmartphoneMockUp.png"
                            alt="Galeri seni brunei app screenshots"
                        />
                    </div>

                    <div class="col-12 d-block d-xl-none text-center">
                        <div class="d-flex flex-column align-items-center">
                            <a
                                href="https://play.google.com/store/apps/details?id=io.huetech.galerisenibrunei"
                                target="_blank"
                                ><img
                                    class="store-logo img"
                                    alt="Get it on Google Play"
                                    src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
                            /></a>

                            <a
                                href="https://apps.apple.com/us/app/galeri-seni-brunei/id1543661561"
                                target="_blank"
                                ><img
                                    class="store-logo store-logo__apple img"
                                    alt="Download on the App Store"
                                    src="../assets/img/appstore.svg"
                            /></a>
                        </div>
                    </div>
                </div>
            </div>
            <div
                class="col-12 d-flex flex-column justify-content-end align-items-center"
            >
                <footer
                    class="d-flex flex-column w-100 justify-content-center align-items-end"
                >
                    <small class="w-100 mt-4 mb-1 text-center text-xl-right">
                        <a
                            class="page__small page__small_link"
                            href="https://huetech.io"
                            target="_blank"
                        >
                            Developed by
                            <img
                                class="d-inline"
                                src="../assets/img/huetech_coloured_logo-16x16.png"
                                alt=""
                            />
                            HUETECH Enterprise
                        </a>
                        <span class="page__small">|</span>
                        <a
                            href="/privacy-policy"
                            class="page__small page__small_link"
                            >Privacy Policy</a
                        >
                        <span class="page__small">|</span>
                        <a
                            href="/terms-and-conditions"
                            class="page__small page__small_link"
                            >Terms & Conditions</a
                        >
                    </small>
                    <small
                        class="page__small w-100 mb-1 text-center text-xl-right"
                    >
                        Copyright &#169; 2020 Ministry of Culture, Youth and
                        Sports
                    </small>
                </footer>
            </div>
        </div>
    </div>
</template>

<script>
var mobile = require('is-mobile')

export default {
    name: 'Home',
    metaInfo: {
        title: 'Galeri Seni Brunei',
        // override the parent template and just use the above title only
        titleTemplate: null,
    },
    mounted() {
        var start_in_browser_btn = this.$refs['vue-start-in-browser']

        if (mobile({ tablet: true })) {
            start_in_browser_btn.classList.add('d-none')
        } else {
            start_in_browser_btn.classList.remove('d-none')
        }
    },
}
</script>
